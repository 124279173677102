<template>
  <en-dropdown @command="operation.add.command">
    <en-button type="primary">创建单据</en-button>
    <template #dropdown>
      <en-dropdown-item command="workorder">工单{{ vehicleServiceGroup?.serviceId ? '(已创建)' : '' }}</en-dropdown-item>
      <en-dropdown-item command="quotation">估价{{ vehicleServiceGroup?.serviceQuotationId ? '(已创建)' : '' }}</en-dropdown-item>
      <en-dropdown-item command="inspection">检测{{ vehicleServiceGroup?.vehicleInspectionId ? '(已创建)' : '' }}</en-dropdown-item>
    </template>
  </en-dropdown>

  <workorder-detail
    v-model="workorder.visible"
    :vehicle="vehicle"
    :customer="customer"
    :vehicle-service-group="vehicleServiceGroup"
    @confirm="workorder.confirm"
  ></workorder-detail>

  <quotation-detail
    v-model="quotation.visible"
    :vehicle="vehicle"
    :customer="customer"
    :vehicle-service-group="vehicleServiceGroup"
    @confirm="quotation.confirm"
  ></quotation-detail>

  <inspection-detail
    v-model="inspection.visible"
    :vehicle="vehicle"
    :customer="customer"
    :vehicle-service-group="vehicleServiceGroup"
    @confirm="inspection.confirm"
  ></inspection-detail>
</template>

<script lang="ts">
import WorkorderDetail from '@service/components/workorder-detail.vue'
import QuotationDetail from '@service/components/quotation-detail.vue'
import InspectionDetail from '@service/components/inspection-detail.vue'

export default factory({
  components: {
    WorkorderDetail,
    QuotationDetail,
    InspectionDetail
  },

  props: {
    from: String as PropType<'workorder' | 'quotation' | 'inspection'>,
    vehicle: Object as PropType<Record<string, any> | undefined>,
    customer: Object as PropType<Record<string, any> | undefined>,
    vehicleServiceGroup: Object as PropType<EnocloudServiceDefinitions['VehicleServiceGroupDto'] | undefined>
  },

  config: {
    children: {
      operation: {
        add: {
          command(option: string) {
            switch (option) {
              case 'workorder':
                this.workorder.visible = true
                break
              case 'quotation':
                this.quotation.visible = true
                break
              case 'inspection':
                this.inspection.visible = true
                break
            }
          }
        }
      },
      workorder: {
        visible: false,
        confirm(id?: number) {
          if (this.from === 'workorder') {
            this.emit('confirm', id)
          } else {
            this.router.push('/service/business/workorder', (vm) => {
              vm.form.data.id = id
              vm.form.get()
            })
          }
        }
      },
      quotation: {
        visible: false,
        confirm(id?: number) {
          if (this.from === 'quotation') {
            this.emit('confirm', id)
          } else {
            this.router.push('/service/business/quotation', (vm) => {
              vm.form.data.id = id
              vm.form.get()
            })
          }
        }
      },
      inspection: {
        visible: false,
        confirm(id?: number) {
          if (this.from === 'inspection') {
            this.emit('confirm', id)
          } else {
            this.router.push('/service/business/inspection', (vm) => {
              vm.form.data.id = id
              vm.form.get()
            })
          }
        }
      }
    }
  },

  mounted() {}
})
</script>
